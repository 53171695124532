/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import Button from "../button"
import { colors } from "../../theme"

const FormSubmit = ({ buttonText }) => {
  return (
    <p className="form-submit-button">
      <Button
        style={{
          color: colors.text,
        }}
        type="submit"
        buttonText={buttonText}
      />
    </p>
  )
}

FormSubmit.propTypes = {
  buttonText: PropTypes.string.isRequired,
}

export default FormSubmit
